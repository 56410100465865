<div bind:this={wrapper} class="tudongchat-com" class:t-hidden={!isInit} class:ltr={position === 'left'}>
  <div bind:this={boxchat} class="tudongchat-box" class:t-hidden={hidden} class:tudongchat-animate-open={animate}>
    <iframe src={iframe_url} title="Main boxchat" frameborder="0"></iframe>
  </div>


  {#if hidden}
  <div>
    {#if message.content}
    <div class="tudongchat-bubble-container">
      <div style="max-width: 350px; position: relative;">
        <div class="tudongchat-bubble-close" on:click={() => message.content = ''}>
          <svg width="22" height="22" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 6L6 18M6 6l12 12"/></svg>
        </div>
        <div class="tudongchat-bubble" style="background: {color};">
          { message.content.slice(0, 200) }{ message.content.length > 200 ? '...' : '' }
        </div>
      </div>
      <div class="tudongchat-bubble-decorator" style="background: {color};" />
      <div style="margin-bottom: 60px;" />
    </div>
    {/if}

    <div bind:this={icon} class="tudongchat-icon" on:click={() => toggle()} style="background-color: {color};">
      <img class="tudongchat-icon-logo" src={app_url + '/images/chat-4-xxl.png'} alt="" />
    </div>
  </div>
  {/if}

  <iframe title="Tracker" class="tracker" src={footprint_url} frameborder="0"></iframe>
</div>

<script lang="ts">
import { app_url, createRequest } from '../../store/target'
import type TuDongChat from '~/TuDongChat'

export let tdc: TuDongChat
export let id: string

const { footprint_url, iframe_url } = createRequest(id)
const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

let isOpen = false
let hidden = true
let isInit = false
let animate = false
let color = '#0598ff'
let position: 'left' | 'right' = 'right'
let message = {
  content: '',
}

let wrapper: HTMLDivElement
let icon: HTMLDivElement
let boxchat: HTMLDivElement

const body = window.document.body
const indexer = {
  current: 20,

  get force () {
    return 2147483644
  },

  get default () {
    return 20
  },

  set default (value: number) {
    this.current = value
  },

  get isReady () {
    return !!wrapper && !!icon && !!boxchat
  },

  update(index?: number) {
    index ??= this.current

    if (this.isReady) {
      wrapper.style.zIndex = index.toString()
      icon.style.zIndex = (index - 1).toString()
      boxchat.style.zIndex = index.toString()
    }

    this.current = index
  }
}

tdc.emitter.on('initial', (box) => {
  isInit = !!box

  if (!box) return

  color = box.color || ''
  indexer.default = box.z_index ?? indexer.default
  position = box.position || 'right'

  if (indexer.isReady) {
    indexer.update()
    box.color && (icon.style.backgroundColor = box.color)
  }
})

const open = () => {
  message.content = ''
  hidden = false
  delay(50).then(() => animate = true)

  if (body.clientWidth <= 460) {
    body.style.overflow = 'hidden'
  }

  indexer.update(indexer.force)
}

const close = () => {
  animate = false
  delay(150).then(() => hidden = true)

  if (body.clientWidth <= 460) {
    body.style.overflow = ''
  }

  indexer.update(indexer.default)
}

tdc.emitter.on('message', (msg) => {
  if (msg.type !== 'internal' || !hidden) return

  message.content = msg.content

  setTimeout(() => {
    if (message.content === msg.content) {
      message.content = ''
    }
  }, 60_000)
})

tdc.emitter.on('show-image', (attachments) => {
  // @ts-ignore
  tdc.display(attachments)
})

const toggle = (force?: boolean) => {
  isOpen = force ?? !isOpen
  isOpen ? open() : close()
}

tdc.emitter.on('close', () => {
  toggle(false)
})
</script>

<style scoped>
.tracker {
  display: none;
}

.tudongchat-com {
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: flex;
  z-index: 20;
}

.tudongchat-com.ltr {
  bottom: 30px;
  left: 30px;
  flex-direction: column-reverse;
}

.tudongchat-com.ltr .tudongchat-icon {
  right: auto;
  left: 0;
}

.tudongchat-icon {
  position: absolute;
  bottom: 0;
  right: 0;

  cursor: pointer;
  background-color: #0598ff;
  border-radius: 100%;
  margin-top: auto;
  transition: all .15s ease-in-out;
  display: grid;
  padding: 15px;
  place-content: center;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.tudongchat-box {
  width: 420px;
  height: 580px;
  border-radius: 10px;
  margin-right: .5rem;

  opacity: 0;
  transition: all .15s ease-in-out;
  transform: scale(.9);
}

.tudongchat-animate-open {
  transform: scale(1);
  opacity: 1;
}

.tudongchat-box > iframe {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.t-hidden {
  display: none;
}

.tudongchat-icon-logo {
  transition: all .15s ease-in-out;
  width: 25px;
  height: 25px;
  max-width: none;
  max-height: none;
}

.tudongchat-icon:hover > .tudongchat-icon-logo {
  transform: scale(1.1);
}

.tudongchat-icon:active > .tudongchat-icon-logo {
  transform: scale(0.8);
}

.tudongchat-bubble-container {
  position: relative;
  flex-direction: column;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.tudongchat-bubble {
  background: brown;
  color: rgb(240, 240, 239);
  display: inline-flex;
  padding: .75rem 1rem;
  border-radius: 10px;
  padding-right: 1.5rem;
}

.tudongchat-bubble-close {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 25px;
  height: 25px;
  color: white;
  border-radius: 100%;
  display: grid;
  place-content: center;
  border: solid 1px black;
  cursor: pointer;
  transition: all;
}

.tudongchat-bubble-close:hover {
  color: red;
}

.tudongchat-bubble-decorator {
  margin-top: -12px;
  margin-right: 20px;
  margin-left: auto;
  width: 20px;
  height: 20px;
  background: brown;
  transform: rotate(45deg);
  border-radius: 4px;
}

.tudongchat-com.ltr .tudongchat-bubble-decorator {
  margin-left: 20px;
  margin-right: auto;
  padding-right: 1rem;
  padding-left: 1.5rem;
}

@media screen and (max-width: 500px) {
  .tudongchat-box {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 0%;
    position: fixed;
  }

  .tudongchat-box > iframe {
    border-radius: 0;
  }

  .tudongchat-com {
    border-radius: 0;
  }
}
</style>
