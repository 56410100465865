import TuDongChat from './TuDongChat'

if (import.meta.env.DEV) {
  const tudongchat = new TuDongChat('STA-6B8tDpwZuyBjAplhp', {
    persistent: true
  })

  tudongchat.initial()

  globalThis.tudongchat = tudongchat
}

if (window) {
  window.TuDongChat = TuDongChat
}

declare global {
  interface Window {
    TuDongChat: typeof TuDongChat
  }
}

export default TuDongChat
