import mitt, { type Emitter } from 'mitt'
import type { Attachment } from '~/TuDongChat'
import TuDongChat from '~/TuDongChat'

export interface Chatbox {
  title: string
  subtitle: string
  color: string
  avatar: string
  z_index: number
  position: 'left'|'right'
}


type Message = {
  _id: string
  content: string
  type: 'internal'|'external'
  createdAt: string
}

type Events = {
  close: void
  open: void
  initial: Chatbox
  message: Message
  'show-image': Attachment[]

  /**
   * reset is not a real event,
   * it's provided to reset the chatbox
   */
  reset: null
}

interface MessageData {
  id?: string
  kind?: 'initial'|'close'|'open'|'message'|'show-image'
  from?: 'tudongchat'
  data?: any
}

type EventMessage = MessageEvent<MessageData>

const validate = (data: MessageData) => {
  return data.kind && data.from === 'tudongchat'
}

export type EmitterEvent = Emitter<Events>

export const createEventHandler = (instance: TuDongChat) => {
  const emitter = mitt<Events>()

  const handler = Object.seal({
    initial: (data: Chatbox) => emitter.emit('initial', data),
    close: () => emitter.emit('close'),
    open: () => emitter.emit('open'),
    message: (message: Message) => emitter.emit('message', message),
    'show-image': (attachments: Attachment[]) => emitter.emit('show-image', attachments)
  })

  window.addEventListener('message', ({ data }: EventMessage) => {
    if (data.id && data.id !== instance.id) {
      return
    }

    if (validate(data)) {
      handler[data.kind]?.(data.data)
    }
  })

  return { emitter }
}
