
<Main id={id} tdc={tdc} />

<script lang="ts">
import Tudongchat from './chat/tudongchat/Tudongchat.svelte'
import { setDevelopment } from './store/target'
import type TuDongChat from './TuDongChat'

export let tdc: TuDongChat
export let id: string
export let dev: boolean = undefined
// export let persistent: boolean = true


let Main = Tudongchat

if (dev !== undefined) {
  setDevelopment(dev)
}
</script>
