import type { ComponentType } from 'svelte'
import Chatbox from './Chatbox.svelte'
import { createEventHandler, type EmitterEvent } from './store/events'
import { inject } from './analytics/plausible'
import GLightbox from 'glightbox'
import 'glightbox/dist/css/glightbox.min.css'
import './style.css'

const createRoot = (id: string) => {
  const root = document.createElement('div')
  root.id = id
  document.body.appendChild(root)
  return root
}

export interface BoxOptions {
  dev: boolean
  persistent: boolean
}

export interface Attachment {
  path: string
  name: string
  size: string
  type: 'image' | 'video'
  mime: string
}

class TuDongChat {
  private el: HTMLElement
  private app: InstanceType<ComponentType<any>>
  private _emitter: EmitterEvent
  public readonly lightbox = GLightbox({
    elements: [],
    preload: true
  })

  constructor(
    public readonly id: string,
    private readonly options?: Partial<BoxOptions>
  ) {
    this.el = createRoot(this.id)
    inject(id)
  }

  initial() {
    this._emitter = createEventHandler(this).emitter

    this.app = new Chatbox({
      target: this.el,
      props: {
        id: this.id,
        tdc: this,
        ...this.options as any
      }
    })
  }

  destroy() {
    this.el.remove()
  }

  reload() {
    if (this.app) {
      this.app.$destroy()
      this.initial()
    }
  }

  popup(show: boolean = true) {
    this.emitter.emit(show ? 'open' : 'close')
  }

  onInit(fn: (data: any) => void) {
    this.emitter.on('initial', fn)
  }

  onClose(fn: () => void) {
    this.emitter.on('close', fn)
  }

  onOpen(fn: () => void) {
    this.emitter.on('open', fn)
  }

  off(fn: any) {
    this.emitter.off(fn)
  }

  get emitter() {
    return this._emitter
  }

  /**
   * Display media attachments
   */
  display(attachments: Attachment[]) {
    this.lightbox.setElements(attachments.map(item => {
      return { href: item.path, type: item.type }
    }))

    // @ts-ignore
    this.lightbox.open()
  }
}

export default TuDongChat
